/* eslint-disable global-require */
<template>
  <div class="contact">
    <img
      :src="require('@/assets/images/tourismeBenin/creds.jpg')"
      alt=""
      class="back-image"
    />
    <div class="filigrane-color" />
    <div class="contact-wrapper">
      <!-- <div class="left-infos">
        <h1>Vous avez une question ?</h1>
        <p>
          Merci de nous contacter en écrivant à cette adresse email:
          mtca.dsisa@gouv.bj. <br />
          Nous mettons tout en oeuvre pour répondre le plus rapidement possible.
        </p>
        <h3>
          Heures d'Ouverture : <br />
          Lundi - Vendredi 8h - 17h30
        </h3>
      </div> -->
      <validation-observer ref="loginForm" v-slot="{ invalid }">
        <b-form @submit.prevent="register" class="contact-form">
          <router-link to="/" class="logo mx-auto">
            <img
              :src="require('@/assets/images/logo/logo_mtca.png')"
              alt="logo"
            />
          </router-link>
          <!-- <p>Inscription OTP</p> -->
      
          <div class="contact-names-email mt-1">
            <b-form-group label="Email" label-for="login-email">
              <validation-provider
                v-slot="{ errors }"
                name="Email"
                vid="email"
                rules="required|email"
              >
                <b-form-input
                  id="login-email"
                  v-model="userEmail"
                  :state="errors.length > 0 ? false : null"
                  name="login-email"
                  placeholder="Entrez votre adresse email"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </div>
          <div>

<b-form-group label="Mot de passe" label-for="login-pass">
  <b-form-input
    id="login-pass"
    v-model="userPassword"
    type="password"
    name="login-pass"
    placeholder="Entrez votre mot de passe"
    />
  </validation-provider>
</b-form-group>
</div>
          <div class="text-center">
            <b-button
              type="submit"
              class="d-flex justify-content-center align-items-center"
              variant="primary"
              :disabled="invalid"
              block
            >
              <b-spinner v-if="isLoading" small class="mr-1" />
              Recevoir le code
            </b-button>
            <div class="text-center mt-1">
              <b-link href="/">
                <small>Retourner à l'accueil</small>
              </b-link>
            </div>
          </div>
        </b-form>
      </validation-observer>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import VuexyLogo from '@core/layouts/components/Logo.vue';
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BAlert,
  VBTooltip,
  BSpinner,
} from 'bootstrap-vue';
import { required, email } from '@validations';
import { togglePasswordVisibility } from '@core/mixins/ui/forms';
// eslint-disable-next-line import/no-cycle
// eslint-disable-next-line import/no-cycle
import { $themeConfig } from '@themeConfig';

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { AbilityBuilder } from '@casl/ability';
import { initialAbility } from '@/libs/acl/config';
import { getHomeRouteForLoggedInUser } from '@/auth/utils';
import store from '@/store/index';
import authStoreModule from '@/store/auth';
import localstorageService from '@/services/localstorage/localstorage.service';
import ColorBandGouvBj from '@/components/ColorBandGouvBj.vue';
import AppLogo from '@/components/AppLogo.vue';
import RecaptchaWidget from '@/components/RecaptchaWidget.vue';
import utilsService from '@/services/utils/utils.service';
import { mapActions } from 'vuex';
import {
  registerStoreModule,
  unregisterStoreModule,
} from '@/helpers/vuex-utils';

export default {
  page() {
    return {
      title: () => this.title,
      meta: [{ name: 'description', content: $themeConfig.app.description }],
    };
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BAlert,
    BSpinner,

    // eslint-disable-next-line vue/no-unused-components
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,

    ColorBandGouvBj,
    AppLogo,
    RecaptchaWidget,
  },
  mixins: [togglePasswordVisibility],
  setup() {
    const requiredStoreModules = [{ path: 'otp', module: authStoreModule }];
    // Register module
    registerStoreModule(requiredStoreModules);
    // App Name
    const { appName, appLogoImage } = $themeConfig.app;
    return {
      requiredStoreModules,
      appName,
      appLogoImage,
    };
  },
  data() {
    return {
      title: 'Connexion',
      status: '',
      password: '',
      userEmail: '',
      userPassword: '',
      sideImg: require('@/assets/images/pages/image-stat-1.jpg'),

      // validation rules
      required,
      email,
      isLoading: false,
      recaptchaResponse: null,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon';
    },
    // imgUrl() {
    //   if (store.state.appConfig.layout.skin === 'dark') {
    //     // eslint-disable-next-line vue/no-side-effects-in-computed-properties
    //     this.sideImg = require('@/assets/images/pages/login-default.png')
    //     return this.sideImg
    //   }
    //   return this.sideImg
    // },
  },
  mounted() {
    const { redirectMotive } = this.$route.params;
    if (redirectMotive && redirectMotive === 'sessionExpired') {
      const errorMessage =
        'Votre session a expiré. Veuillez vous reconnecter pour accéder à votre compte.';
      this.$toast({
        component: ToastificationContent,
        props: {
          title: errorMessage,
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      });
    }
  },
  methods: {
    ...mapActions('imagePuzzleSliderCaptcha', { showSliderCaptcha: 'show' }),
    ...mapActions('otp', {
      action_registerByOtp: 'registerByOtp',
    }),
    register() {
      this.$refs.loginForm.validate().then(async (success) => {
        if (success) {
          this.isLoading = true;
          // ReCaptcha v3
          // (optional) Wait until recaptcha has been loaded.
          await this.$recaptchaLoaded();
          // Execute reCAPTCHA with action "login".
          const token = await this.$recaptcha('login');
          if (!token) {
            this.isLoading = false;
            return;
          }

          const params = {
            email: this.userEmail,
            token,
          };

          // this.action_loginByOtp
          this.action_registerByOtp(params)
            .then((res) => {
              // console.log('✅✅ EVERYTHING GOOD');
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title:
                    'Un code de réinitialisation vous a été envoyer. Veuillez consulter votre adresse mail',
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              });
              this.$router.push({
                name: 'otp-login',
                query: { email: this.userEmail },
              });
              // success alert
              this.isLoading = false;
            })
            .catch((err) => {
              console.log('err: ', err);
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: err.message || "L'adresse email est invalide.",
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              });
              this.isLoading = false;
            });
        }
      });
    },

    onRecaptchaChange(recaptchaEvent) {
      if (recaptchaEvent && recaptchaEvent.type === 'success') {
        this.recaptchaResponse = recaptchaEvent;
      } else {
        this.recaptchaResponse = null;
      }
    },
    updateAbility() {
      const { can, rules } = new AbilityBuilder();

      if (user.role === 'admin') {
        can('manage', 'all');
      } else {
        can('read', 'all');
      }

      this.$ability.update(rules);
    },
  },
};
</script>

<style scoped>
.contact {
  position: relative;
  width: 100%;
  min-height: 100vh;
}
.contact-wrapper {
  margin: 0 auto;
  max-width: 500px;
  height: 100%;
  min-height: 100vh;

  padding: 3em 1em;
  /* display: grid; */
  /* grid-template-columns: 1fr 1fr; */
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  gap: 3em;
}
.contact .back-image {
  position: absolute;
  inset: 0;
  z-index: -20;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.contact .filigrane-color {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  z-index: -10;

  background-color: #06250fb2;
}

.left-infos {
  color: #fff;
  text-shadow: 2px 2px 1px #00000090;
}
.left-infos h1 {
  color: #fff;
  font-weight: bold;
  font-size: 35px;
}
.left-infos h3 {
  color: #fff;
}
.left-infos p {
  margin: 1em 0;
  line-height: 30px;
  color: #fff;
  font-size: 16px;
}

.contact-form {
  border-radius: 1em;
  padding: 2em;
  background-color: #fff;
  min-width: 330px;
  /* max-width: 1300px; */
}
.contact-form .logo {
  max-width: 190px;
  display: block;
}
.contact-form .logo img {
  width: 190px;
}
.contact-form p {
  margin: 1em auto;
  font-size: 16px;
  text-align: center;
}
.contact-names-email {
  display: grid;
  gap: 0 2em;
}
/* MEDIA QUERIES */
@media (min-width: 576px) {
  .contact-wrapper {
    flex-direction: row;
    padding: 4em 3em;
  }
  .contact-form {
    padding: 3em;
  }
  .contact-form {
    width: 100%;
    max-width: 650px;
  }
  .left-infos {
    width: 55%;
  }
}
@media (min-width: 768px) {
}
@media (min-width: 992px) {
  .left-infos h1 {
    font-size: 55px;
  }
  .left-infos p {
    font-size: 25px;
  }
}
@media (min-width: 1200px) {
}
@media (min-width: 1400px) {
}
</style>
